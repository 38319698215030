import React from "react";

import './Header.scss';

const Header = () => {
    return (
        <div className='header-container'>
            <div className='header'>
                <h1 className='header-title'>DJ SCrib</h1>
                <h2 className='header-subtitle'>The Basquiat of DJs</h2>
                <div className='social-icon-row'>
                    <a href="https://www.facebook.com/djscrib06/" rel="noopener noreferrer" target="_blank">
                        <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/icons/facebook.svg' alt='fb' className='social-icon'/>
                    </a>
                    <a href="https://twitter.com/djscrib06" rel="noopener noreferrer" target="_blank">
                        <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/icons/twitter.svg' alt='twitter' className='social-icon'/>
                    </a>
                    <a href="https://www.instagram.com/djscrib06" rel="noopener noreferrer" target="_blank">
                        <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/icons/instagram.svg' alt='ig' className='social-icon'/>
                    </a>
                    <a href="https://www.soundcloud.com/djscrib-06" rel="noopener noreferrer" target="_blank">
                        <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/icons/soundcloud.svg' alt='soundcloud' className='social-icon'/>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Header;
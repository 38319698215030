import React from "react";

import './Footer.scss';
import Mailchimp from "../Mailchimp/Mailchimp";

const Footer = () => {
    return (
        <>
            <Mailchimp/>
            <div className='footer'>
                <p>In collaboration with <a href="http://www.joyetotheworld.com" target="_blank"
                                            rel="noopener noreferrer">Joye
                    to the World <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/jttw-favicon.ico'
                                      alt='Joye to the World'/></a></p>
            </div>
        </>
    );
};

export default Footer;
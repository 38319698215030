import React from "react";

import Field from "../Contact/Field/Field";
import {useState} from "preact/hooks";
import './Mailchimp.scss';
import Button from "../Button/Button";
import ScrollAnimation from "react-animate-on-scroll";

const Mailchimp = () => {
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        const reqData = {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/javascript, */*',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            body: JSON.stringify({
                email: email,
            }),
        };
        await fetch('https://djscrib.us17.list-manage.com/subscribe/post?u=02aad566f0c02399d73faaf4c&amp;id=afaec1b52e', reqData)
            .then(({response}) => {
                console.log('Response', response);
                console.log('Email captured successfully!', email)
            })
            .catch((error) => console.log('Something went wrong with email.', error));
    }

    return (
        <div className='mailchimp'>
            <ScrollAnimation animateIn="fadeIn">
            <h1>Join the Team</h1>
            <h2>Join our mailing list to be the first to hear about new playlists and mixes, events, and news!</h2>
            <div>
                    <div id="mc_embed_signup">
                        <form action="https://djscrib.us17.list-manage.com/subscribe/post?u=02aad566f0c02399d73faaf4c&amp;id=afaec1b52e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
                            <fieldset className="formGroup">
                                        <Field
                                            label="Name"
                                            id="mce-FNAME"
                                            type="text"
                                            name="FNAME"
                                            placeholder="First Name"
                                            required
                                            autoComplete="text"
                                            value={firstName}
                                            onChange={(e) => {
                                                setFirstName(e.target.value);
                                            }}
                                        />
                                        <Field
                                            label="Email"
                                            id="mce-EMAIL"
                                            type="email"
                                            name="EMAIL"
                                            placeholder="Email"
                                            required
                                            autoComplete="email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                        />
                                    </fieldset>
                                    <div className='captcha' aria-hidden="true">
                                        <input type="text"
                                               name="b_02aad566f0c02399d73faaf4c_afaec1b52e"
                                               tabindex="-1" value=""/>
                                    </div>
                                    <div className="clear">
                                        <Button/>
                                     </div>
                        </form>
                    </div>
            </div>
            </ScrollAnimation>
        </div>
    );
};

export default Mailchimp;
import React from "react";

import './CofcHusbands.scss';
import ScrollAnimation from "react-animate-on-scroll";

const CofcHusbands = () => {
    return (
        <div className='center-content-column cofc-husbands'>
            <div className='center-content-column cofc-husbands-text'>
                <ScrollAnimation animateIn="fadeIn">
                    <h1 className='cofc-husbands-header'>Real Husbands of CofC</h1>
                    <p>
                        The Real Husbands of CofC are a group of College of Charleston alumni who came together to bring
                        you
                        our perspective on what we think it means to be a husband while providing entertainment as we
                        discuss current events and other facets of everyday Husband engagement.
                    </p>
                    <div className='social-icon-row'>
                        <a href="https://www.instagram.com/cofchusbands" rel="noopener noreferrer" target="_blank">
                            <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/icons/instagram.svg' alt='ig' className='social-icon'/>
                        </a>
                        <a href="https://www.youtube.com/channel/UCJtwwza6OjIfz7VTBtoVNYg" rel="noopener noreferrer"
                           target="_blank">
                            <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/icons/youtube.svg' alt='youtube' className='social-icon'/>
                        </a>
                    </div>
                    <div className='videos'>
                        <iframe
                            title='Real Husbands of CofC Trailer'
                            src="https://www.youtube.com/embed/mheD3JimwyM" frameBorder="0" allowFullScreen/>
                    </div>
                </ScrollAnimation>
            </div>
        </div>
    );
};

export default CofcHusbands;
import React from "react";

import './Publication.scss';

const Publication = ({pic, header, date, publicationLink}) => {
    return (
        <div className='center-content-column publication'>
            <img className='publication-pic' src={pic} alt={header}/>
            <div className='center-content-column publication-preview'>
                <h1 className='publication-header'>{header}</h1>
                <p className='publication-date'>{date}</p>
                <a className='scrib-link read-more'
                   href={publicationLink}
                   rel="noopener noreferrer" target="_blank">Read more</a>
            </div>
        </div>
    );
};

export default Publication;
import React from "react";
import {useEffect, useState} from "preact/hooks";
import ScrollAnimation from "react-animate-on-scroll";

import InstagramPic from "./InstagramPic/InstagramPic";

import './InstagramFeed.scss';

const InstagramFeed = () => {
    const [instaPics, setInstaPics] = useState([]);

    const basicIgToken = 'IGQVJYZAEw2T1Nzc2I3UmIzZAUI0RE1CN1pqZAjV4MlRyNWVjNEpDZA3JSU2pVVXZAldVh4ZAmpPdnRXb0pDLUg5czJtWVplMlBBWERWTm5jQTZAmVkE3WGNzczI4UmlSQnRreEhyNmhmNEpxMFQ3bEVtTlh3VAZDZD';

    const getInstaPics = async () => {
        const reqData = {
            method: 'GET'
        };
        const response = await fetch(`https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,permalink,thumbnail_url,timestamp&access_token=${basicIgToken}`, reqData);
        return response.json();
    }

    useEffect(() => {
        getInstaPics()
            .then(res => {
                if (!res.error) {
                    const onlyContentWithThumbnails = res.data.slice(0, 8);
                    setInstaPics(onlyContentWithThumbnails);
                }
            })
    }, []);

    return (instaPics.length > 0) ?
        (
            <div className='instagram'>
                <h1>SCrib Chronicles</h1>
                <div className='instagram-feed'>
                    {
                        instaPics.map(({id, caption, media_url, permalink, media_type, thumbnail_url}) => {
                            const picUrl = media_type === 'VIDEO' ? thumbnail_url : media_url;
                            return(
                            <ScrollAnimation animateIn="fadeIn" delay={100} key={id}>
                                <InstagramPic
                                    pic={picUrl}
                                    caption={caption}
                                    permalink={permalink}
                                />
                            </ScrollAnimation>
                        )}
                        )
                    }
                </div>
                <hr className='divider'/>
            </div>
        ) : null
};

export default InstagramFeed;
import React from 'react';
import ReactDOM from 'react-dom';
// import ReactGA from "react-ga";

import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import ttiPolyfill from 'tti-polyfill';
// import {createBrowserHistory} from "history";
import './index.css';

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
    // console.log('TTI:', tti);
});

const callback = list => {
    list.getEntries().forEach(entry => {
        // console.log('Server Latency', entry.responseStart - entry.requestStart);
        // console.log('Download Time', entry.responseEnd - entry.responseStart);
        // console.log('Total app load time', entry.responseEnd - entry.requestStart);
    })
}

const observer = new PerformanceObserver(callback);
observer.observe({entryTypes: ['navigation']})

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

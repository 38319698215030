import React from "react";

import {Link} from "react-router-dom";
import './AboutText.scss';

const AboutText = () => {
    return (
            <div className='about-text-wrapper'>
                <div className='center-content-column'>
                    <h1 className='about-text-header'>I'm SCrib.</h1>
                </div>
                <div className='about-text'>
                    <p>The question DJ SCrib is always asked is, “How did you even blend that?” DJ SCrib, born James
                        Vickers, is a DJ, teacher, graphic designer and content creator. The Charleston, SC born native
                        is known for his flawless blending skills, comedic MCing, and a very diverse music library to
                        fit any audiences’ requests.</p>

                    <Link to='/bio' className='scrib-link'>Learn more</Link>
                </div>
            </div>
    );
};

export default AboutText;
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import './Family.scss';

const Family = () => {
    return (
        <ScrollAnimation animateIn="fadeIn">
            <div className='family-wrapper'>
                <div className='family-pic'>
                    <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/family/christmas.png' alt='The Vickers'/>
                    <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/family/air-journ.png' alt='The Vickers' className='airJourn'/>
                    <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/family/christmas.png' alt='The Vickers' className='duplicate-image'/>
                    <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/family/air-journ.png' alt='The Vickers' className='airJourn duplicate-image'/>
                </div>
                <div className='center-content-column family-text'>
                    <h1 className='family-header'>Family, first</h1>
                    <p className='family-body'>
                        I fell in love with my wife the first day I saw her walking on campus! Since getting married in
                        2017, we have been blessed with our musical, soul daughter who dances to every tune and even
                        scratches on the tables here and there.
                    </p>
                </div>
            </div>
        </ScrollAnimation>
    );
};

export default Family;
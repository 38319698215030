import React from "react";
import {useState} from "preact/hooks";
import Field from "./Field/Field";
import TextArea from "./TextArea/TextArea";
import ScrollAnimation from "react-animate-on-scroll";
import './Contact.scss';
import Button from "../Button/Button";

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [emailStatus, setEmailStatus] = useState(undefined);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const reqData = {
            method: 'POST',
            headers: {
                Accept: 'application/json, text/javascript, */*',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: name,
                email: email,
                subject: subject,
                message: message
            }),
        };
        await fetch('https://formspree.io/mrgyjbdg', reqData)
            .then(({status}) => {
                setEmailStatus(status);
            })
            .catch((error) => console.log('Something went wrong with email.', error));
    }

    const successfulEmailMessage = 'Email sent successfully! We will be in touch!';
    const errorEmailMessage = 'We\'re sorry! Something went wrong. Please refresh and try again!';

    return (
        <div className='center-content-column shadow contact'>
            <h1 className='contact-header'>Want SCrib at your next event?</h1>
            {
                emailStatus ?
                    (
                        <h2 className='contact-response'> {emailStatus === 200 ? successfulEmailMessage : errorEmailMessage}</h2>
                    ) :
                    (
                        <ScrollAnimation animateIn="fadeIn">
                            <form className="form checkout-form-container" onSubmit={handleSubmit}>
                                <fieldset className="formGroup">
                                    <Field
                                        label="Name"
                                        id="name"
                                        type="text"
                                        placeholder="Name"
                                        required
                                        autoComplete="name"
                                        value={name}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                    />
                                </fieldset>
                                <fieldset className="formGroup">
                                    <Field
                                        label="Email"
                                        id="email"
                                        type="email"
                                        placeholder="Email"
                                        required
                                        autoComplete="email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    />
                                </fieldset>
                                <fieldset className="formGroup">
                                    <Field
                                        label="Subject"
                                        id="subject"
                                        type="text"
                                        placeholder="Subject"
                                        required
                                        autoComplete="subject"
                                        value={subject}
                                        onChange={(e) => {
                                            setSubject(e.target.value);
                                        }}
                                    />
                                </fieldset>
                                <fieldset className="formGroup">
                                    <TextArea
                                        label="Message"
                                        id="message"
                                        type="text"
                                        placeholder="Your message here!"
                                        required
                                        value={message}
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                    />
                                </fieldset>
                                <Button />
                            </form>
                        </ScrollAnimation>
                    )
            }
        </div>
    );
};

export default Contact;
import React from "react";

import './InstagramPic.scss';

const InstagramPic = ({pic, caption, permalink}) => {

    return (
        <div className='instagram-pic'>
            <a href={permalink} rel="noopener noreferrer" target="_blank">
                <img src={pic} alt={caption}/>
            </a>
        </div>
    )
};

export default InstagramPic;
import React from "react";

import CounterWithCaption from "../CounterWithCaption/CounterWithCaption";

import './Counter.scss';

const Counter = () => {
    return (
        <div className='counter'>
            <CounterWithCaption end={200} caption='Mixes'/>
            <CounterWithCaption end={100} caption='Shows'/>
            <CounterWithCaption end={700} caption='Parties'/>
            <CounterWithCaption end={200} caption='Events'/>
        </div>
    );
};

export default Counter;
import React from "react";

import Publication from "../Publication/Publication";
import {Carousel} from "react-responsive-carousel";

import './News.scss';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const News = () => {

    const publications = [
        {
            pic: 'https://today.cofc.edu/wp-content/uploads/2020/08/20200815_HusbandsCofC_179_HM.jpg',
            header: 'Meet the Real Husbands of CofC',
            date: 'August 27, 2020',
            publicationLink: "https://today.cofc.edu/2020/08/27/meet-the-real-husbands-of-cofc/",
        },
        {
            pic: 'https://media2.fdncms.com/charleston/imager/u/blog/32102942/djscrib-blk.jpg?cb=1596831152',
            header: 'DJ Scrib featured as guest DJ on Sway in the Morning',
            date: 'August 8, 2020',
            publicationLink: "https://www.charlestoncitypaper.com/FeedbackFile/archives/2020/08/08/dj-scrib-featured-as-guest-dj-on-sway-in-the-morning",
        },        {
            pic: 'https://media2.fdncms.com/charleston/imager/u/magnum/30094009/thumb_img_6046_1024.jpg',
            header: 'Artists from across South Carolina come together for a two-day recording session',
            date: 'December 11, 2019',
            publicationLink: "https://www.charlestoncitypaper.com/charleston/artists-from-across-south-carolina-come-together-for-a-two-day-recording-session/Content?oid=30094010",
        },
        {
            pic: 'https://media1.fdncms.com/charleston/imager/u/magnum/29849022/djscrib-10.jpg',
            header: 'DJ of the Year: DJ SCrib',
            date: 'November 13, 2019',
            publicationLink: "https://www.charlestoncitypaper.com/charleston/dj-of-the-year-dj-scrib/Content?oid=29849023",
        },
        {
            pic: 'https://media2.fdncms.com/charleston/imager/u/blog/27981863/djscrib-blk.jpg?cb=1555433113',
            header: 'Cultura Clips: Two minutes with DJ SCrib',
            date: 'April 19, 2019',
            publicationLink: "https://www.charlestoncitypaper.com/FeedbackFile/archives/2019/04/19/cultura-clips-two-minutes-with-dj-scrib",
        },
        {
            pic: 'https://media2.fdncms.com/charleston/imager/u/blog/7986878/dj_scrib_15_jwb.jpg?cb=1493259876',
            header: 'Live Music: Trap Serenade; Salt-N-Pepa; Diaspoura; Down Under',
            date: 'April 26, 2017',
            publicationLink: "https://www.charlestoncitypaper.com/FeedbackFile/archives/2017/04/26/live-music-trap-serenade-salt-n-pepa-diaspoura-down-under",
        }
    ];

    return (
        <div className='center-content-column news'>
            <h1 className='news-title'>In the News</h1>
            <Carousel
                autoPlay={true}
                showArrows={true}
                infiniteLoop={true}
                swipeable={true}
                showIndicators={false}
                useKeyboardArrows={true}
                showStatus={false}
                showThumbs={false}
                stopOnHover={true}
                interval={3000}
            >
                {
                    publications.map(({pic, header, date, publicationLink}) =>
                        (
                            <Publication
                                key={date}
                                pic={pic}
                                header={header}
                                date={date}
                                publicationLink={publicationLink}
                            />)
                    )
                }
            </Carousel>
        </div>
    );
};

export default News;
import React from "react";

import './Button.scss';

const Button = () => {
    return (
        <button
            className='submitButton'
            type="submit"
            name="subscribe"
        >
            Submit
        </button>
    );
};

export default Button;
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import './Twitch.scss';

const Twitch = () => {
    return (
        <div className='center-content-column twitch'>
            <ScrollAnimation animateIn="fadeInUp">
                <iframe
                    src="https://player.twitch.tv/?channel=djscrib06&parent=www.djscrib.com&parent=djscrib.com&parent=djscrib-prod.herokuapp.com&parent=djscrib-staging.herokuapp.com"
                    title="DJ SCrib Live" frameBorder="0" allowFullScreen={true} scrolling="no"/>
            </ScrollAnimation>
        </div>
    );
};

export default Twitch;
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import './MisterVickers.scss';

const MisterVickers = () => {
    return (
        <div className='mister-vickers-wrapper'>
            <div className='center-content-column mister-vickers-text'>
                <h1 className='mister-vickers-header'>Call Me Mister.</h1>
                <p>During the day, I am a Science teacher at Meeting Street Academy in Charleston, South Carolina. I
                    make sure my kids learn through song and dance, projects, open discussions, books/articles and
                    written assignments.</p>
            </div>
            <ScrollAnimation animateIn="fadeInRight">
                <div className='mister-pic'>
                    <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/mister.png' alt='Mr. Vickers'/>
                </div>
            </ScrollAnimation>
        </div>
    );
};

export default MisterVickers;
import React from "react";
import {useEffect} from "preact/hooks";

import './About.scss';
import AboutHeader from "../../components/AboutHeader/AboutHeader";
import Bio from "../../components/Bio/Bio";
import Family from "../../components/Family/Family";
import MisterVickers from "../../components/MisterVickers/MisterVickers";
import CofcHusbands from "../../components/CofCHusbands/CofcHusbands";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

const About = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='about-page'>
            <Navbar currentPage={'About'}/>
            <AboutHeader />
            <Bio />
            <Family />
            <MisterVickers />
            <CofcHusbands />
            <Footer />
        </div>
    )
};

export default About;
import React from "react";

import './LiveHeader.scss';

const LiveHeader = () => {
    return (
        <div className='live-header-container'>
            <div className='live-header'>
                <h1 className='live-header-title'>We live baby!</h1>
            </div>
        </div>
    );
}

export default LiveHeader;
import React from "react";
import {useEffect} from "preact/hooks";

import LiveHeader from "./LiveHeader/LiveHeader";
import Twitch from "../../components/Twitch/Twitch";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";

import './Live.scss';

const Live = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='live-page'>
            <Navbar currentPage={'Live'}/>
            <LiveHeader />
            <Twitch />
            <Footer />
        </div>
    )
};

export default Live;
import React from "react";

import './AboutHeader.scss';

const AboutHeader = () => {
    return (
        <div className='about-header-container'>
            <div className='about-header'>
                <h1 className='about-header-title'>Get to know SCrib</h1>
            </div>
        </div>
    );
}

export default AboutHeader;
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import AboutText from "./AboutText/AboutText";
import AboutGallery from "./AboutGallery/AboutGallery";

import './MiniAbout.scss';

const MiniAbout = () => {
    return (
        <div className='mini-about'>
            <AboutText/>
            <ScrollAnimation animateIn="fadeInUp">
                <AboutGallery/>
            </ScrollAnimation>
        </div>
    );
};

export default MiniAbout;
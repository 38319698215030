import React from "react";

import './Bio.scss';

const Bio = () => {
    return (
        <div className='bio'>
            <p>With more than 10 years experience and hundreds of professional appearances, DJ SCrib (otherwise
                known as James Vickers) has become a major staple in entertainment in Charleston, SC.

                DJ SCrib is known to be a nostalgic story teller through each DJ set he performs. He seeks to
                create unique blends that explore beyond saturated and repetitive sounds. His collection of
                music over the years plays a vital role in creating an experience for an audience to remember at
                every event.

                SCrib has proven to be ambitious and an amazing content creator for his community. He has
                fulfilled a large list of events such as corporate events, festivals, college events, fashion
                shows and weddings.

                DJ SCrib was awarded by the Charleston City Paper as the 2019 DJ of the Year. The following
                year, SCrib was nominated for 2020 ‘Best Club DJ of the Year’ and placed as the Runner-Up
                against 5 other DJs. In 2020, SCrib joined OG Ron C and The Chopstars/Mixstars where he creates
                Chopped Not Slopped remixes for mainstream and indie artists to continue the legacy of the
                legendary DJ Screw. Following the Chopstars/Mixstars, SCrib joined another coalition known as
                the Fleet DJs where he shares his experience as a DJ and receives strategies to build as an
                entrepreneur too.

                He takes inspiration from musical icons such as DJ Jam Master Jay, DJ Jazzy Jeff, and Miles
                Medina. SCrib consistently reassures his audience of his blending abilities and nostalgic music
                selection. When he’s not on turntables, DJ SCrib enjoys spending time with his family, teaching,
                and working on graphic designs. DJ SCrib continually pushes himself professionally and
                musically, as well as hopes to become a syndicated radio DJ, producer and music director.

                “Have you ever heard Taylor Swift to Kanye West? Well you hear that from me!”</p>
        </div>
    );
};

export default Bio;
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import './AboutGallery.scss';

const AboutGallery = () => {
    return (
        <div className='about-gallery'>
            <ScrollAnimation animateIn="fadeInUp">
                <img className='about-pic' src='https://storage.googleapis.com/dj-scrib.appspot.com/images/gallery/brown.png' alt='DJ SCrib'/>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <img className='about-pic' src='https://storage.googleapis.com/dj-scrib.appspot.com/images/gallery/jersey.png' alt='DJ SCrib'/>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <img className='about-pic' src='https://storage.googleapis.com/dj-scrib.appspot.com/images/gallery/standing.png' alt='DJ SCrib'/>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <img className='about-pic' src='https://storage.googleapis.com/dj-scrib.appspot.com/images/gallery/pole.png' alt='DJ SCrib'/>
            </ScrollAnimation>
        </div>
    );
};

export default AboutGallery;
import React from "react";
import {useEffect} from "preact/hooks";
import ScrollAnimation from 'react-animate-on-scroll';

import Header from "../../components/Header/Header";

import MiniAbout from "../../components/MiniAbout/MiniAbout";
import Counter from "../../components/Counter/Counter";

import './Home.scss';
import News from "../../components/News/News";
import Team from "../../components/Team/Team";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import Masterpieces from "../../components/Masterpieces/Masterpieces";
import Navbar from "../../components/Navbar/Navbar";
import InstagramFeed from "../../components/InstagramFeed/InstagramFeed";

const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div className='home'>
            <Navbar/>
            <Header/>
            <MiniAbout/>
            <div className='work-wrapper'>
                <Counter/>
                <News/>
            </div>
            <ScrollAnimation animateIn="fadeIn">
                <Team/>
            </ScrollAnimation>
            <Masterpieces/>
            <Contact/>
            <InstagramFeed/>
            <Footer/>
        </div>
    );
};

export default Home;
import React from 'react';
import {Route, Switch} from "react-router-dom";
import {useEffect, useState} from "preact/hooks";

import Home from "./pages/Home/Home";
import About from "./pages/About/About";
import Live from "./pages/Live/Live";

import './App.css';

function App() {

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        demoAsyncCall().then(() => setLoading(false));
    }, [])
    function demoAsyncCall() {
        return new Promise((resolve) => setTimeout(() => resolve(), 2500));
    }

    if(loading) { // if your component doesn't have to wait for an async action, remove this block
        return null; // render null when app is not ready
    }

    return (
        <div className="App">
            <Switch>
                <Route exact path='/live' component={Live}/>
                <Route exact path='/bio' component={About}/>
                <Route exact path='/auth' component={Home}/>
                <Route path='/' component={Home}/>
            </Switch>
        </div>
    );
}

export default App;

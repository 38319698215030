import React from "react";

import './Team.scss';

const Team = () => {
    return (
        <div className='center-content-row shadow team'>
            <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/chopstars.png' alt='Chopstars'/>
            <img src='https://storage.googleapis.com/dj-scrib.appspot.com/images/fleet-djs.png' alt='Fleet DJs'/>
        </div>
    );
};

export default Team;
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

import './Masterpieces.scss';

const featuredMasterpieces = [
    {
        musicLink: 'https://www.mixtapeheat.net/?wpdmpro=dj-shells-dj-scrib-drip-radio-presents-pandemic-vol-3-twerk-edition',
        imageSrc: 'https://www.mixtapeheat.net/wp-content/uploads/thenext-thumb-cache//PAND-5b6a97895f92695d9050436d5d61266a-900x0.jpg',
        imageAlt: 'DJ Shells & DJ Scrib DRIP RADIO PRESENTS PANDEMIC VOL 3 (Twerk Edition)'
    },
    {
        musicLink: 'https://www.mixcloud.com/djscrib06/dpm-x-the-nightlife-jungle-juice-the-playlist/',
        imageSrc: 'https://thumbnailer.mixcloud.com/unsafe/300x300/extaudio/9/f/8/d/8959-796b-4099-8c19-99fa54398d58',
        imageAlt: 'DPM X The Nightlife: Jungle Juice, The Playlist'
    },
    {
        musicLink: 'https://www.mixcloud.com/djscrib06/djscrib06-sway-in-the-morning-dj-set/?utm_campaign=notification_new_upload&utm_medium=email&utm_source=notification&utm_content=html',
        imageSrc: 'https://thumbnailer.mixcloud.com/unsafe/300x300/extaudio/a/5/d/e/07d0-8ff1-4f74-ba0b-3639be4937ce',
        imageAlt: 'DJ SCrib x Sway In the Morning'
    },
    {
        musicLink: 'https://www.mixtapeheat.net/?wpdmpro=dj-shells-best-of-juvenile-boy',
        imageSrc: 'https://storage.googleapis.com/dj-scrib.appspot.com/images/mixes/nolia-boy.png',
        imageAlt: 'Nolia Boy: The Mixtape'
    },
    {
        musicLink: 'https://www.mixcloud.com/djscrib06/chill-vibes-only/',
        imageSrc: 'https://storage.googleapis.com/dj-scrib.appspot.com/images/mixes/chill-vibes.png',
        imageAlt: 'Chill Vibes Only'
    },
    {
        musicLink: 'https://www.mixcloud.com/djscrib06/get-lit-the-mixshow/',
        imageSrc: 'https://storage.googleapis.com/dj-scrib.appspot.com/images/mixes/mix-show-hh.png',
        imageAlt: 'DJ SCrib In the Mix: Hip-hop/Rap and R&B'
    },
    {
        musicLink: 'https://www.mixtapeheat.net/?wpdmpro=dj-shells-dj-scrib-kickback-n-chill&fbclid=IwAR08JZ7q785pAWZ8YugHfGq48yIaWKFD-UET_sdECkSPSUMhvioCU9T7SOw',
        imageSrc: 'https://storage.googleapis.com/dj-scrib.appspot.com/images/mixes/kickback.png',
        imageAlt: 'Kickback & Chill'
    },
    {
        musicLink: 'https://www.mixcloud.com/djscrib06/carolina-lockdown-mix-71620/',
        imageSrc: 'https://storage.googleapis.com/dj-scrib.appspot.com/images/mixes/carolina-lockdown.png',
        imageAlt: 'DJ SCrib: Carolina Lockdown Mix'
    },
    {
        musicLink: 'https://www.mixtapeheat.net/?wpdmpro=dj-shells-best-of-gucci-mane',
        imageSrc: 'https://storage.googleapis.com/dj-scrib.appspot.com/images/mixes/gucci-mane.png',
        imageAlt: 'The Best of Gucci Mane'
    },
];

const Masterpieces = () => {
    return (
        <div className='masterpieces'>
            <h1 className='masterpiece-header'>The Masterpieces</h1>
            {/*<ScrollAnimation animateIn="fadeIn" key='sway'>*/}
            {/*    <div className='featured-mix-wrapper'>*/}
            {/*        <a href='https://www.mixcloud.com/djscrib06/djscrib06-sway-in-the-morning-dj-set/?utm_campaign=notification_new_upload&utm_medium=email&utm_source=notification&utm_content=html'*/}
            {/*           rel="noopener noreferrer"*/}
            {/*           target="_blank"*/}
            {/*           className='center-content-column'>*/}

            {/*        <img*/}
            {/*            alt='DJ SCrib x Sway In the Morning'*/}
            {/*            src={sway}*/}
            {/*            className='featured-mix'*/}
            {/*        />*/}
            {/*        </a>*/}
            {/*        <div className='mix-icons'>*/}
            {/*            <a href='https://music.apple.com/us/playlist/djscrib06-sway-in-the-morning-dj-set-list/pl.u-4Jomm92FdRM5jJ'*/}
            {/*               rel="noopener noreferrer"*/}
            {/*               target="_blank"*/}
            {/*                className='center-content-column'>*/}
            {/*                <img*/}
            {/*                    alt='Apple Music'*/}
            {/*                    src={apple}*/}
            {/*                    className='featured-mix-icon'*/}
            {/*                />*/}
            {/*            </a>*/}
            {/*            <a href='https://open.spotify.com/playlist/74helKbBaLGgHMe4Pvzfod?si=_jb-3DdWQJSv_ap2-913-A&nd=1&nd=1'*/}
            {/*               rel="noopener noreferrer"*/}
            {/*               target="_blank"*/}
            {/*                className='center-content-column'>*/}
            {/*                <img*/}
            {/*                    alt='DJ SCrib x Sway In the Morning'*/}
            {/*                    src={spotify}*/}
            {/*                    className='featured-mix-icon'*/}
            {/*                />*/}
            {/*            </a>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</ScrollAnimation>*/}
            <div className='masterpiece-wrapper'>
                {
                    featuredMasterpieces.map(({musicLink, imageSrc, imageAlt}) => (
                        <ScrollAnimation animateIn="fadeInUp" key={imageAlt}>
                            <div className='mix-wrapper'>
                                <a href={musicLink}
                                   rel="noopener noreferrer"
                                   target="_blank">
                                    <img
                                        alt={imageAlt}
                                        src={imageSrc}
                                    />
                                </a>
                            </div>
                        </ScrollAnimation>
                    ))
                }
            </div>
        </div>
    );
};

export default Masterpieces;
import React from "react";

import './CounterWithCaption.scss';

const CounterWithCaption = ({caption, end}) => {
    return (
        <div className='counter-with-caption center-content-column'>
            <span>{end}+</span>
            {caption && <h1 className='caption'>{caption}</h1>}
        </div>
    );
};

export default CounterWithCaption;